<template>
  <div class="container-fluid main-color">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">Module Management</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <place-holder v-if="loading"></place-holder>
          <div v-if="!loading" class="card-body">
            <form class="parsley-examples">
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="name" class="form-label">
                      Module Title <span class="text-danger">*</span>
                    </label>
                    <input
                      v-model="module.name"
                      type="text"
                      id="name"
                      name="name"
                      class="form-control"
                      placeholder="Type Module Title"
                      :class="{
                        'p-invalid': v$.module.name.$error || errorFor('name'),
                      }"
                    />
                    <v-errors
                      :serverErrors="errorFor('name')"
                      :vuelidateErrors="{
                        errors: v$.module.name.$errors,
                        value: 'Name',
                      }"
                    ></v-errors>
                  </div>
                  <div class="mb-3">
                    <label for="short_name" class="form-label">
                      Module Short Name
                      <span class="text-danger">*</span>
                    </label>
                    <input
                      v-model="module.short_name"
                      type="text"
                      id="short_name"
                      name="short_name"
                      class="form-control"
                      placeholder="Type Module Short Name"
                      :class="{
                        'p-invalid':
                          v$.module.short_name.$error || errorFor('short_name'),
                      }"
                    />
                    <v-errors
                      :serverErrors="errorFor('short_name')"
                      :vuelidateErrors="{
                        errors: v$.module.short_name.$errors,
                        value: 'Short Name',
                      }"
                    ></v-errors>
                  </div>
                  <div>
                    <label for="image">
                      Upload Image <span class="text-danger">*</span>
                    </label>
                    <section>
                      <div
                        width="40%"
                        class="upload dialog-center"
                        style="cursor: pointer"
                      >
                        <div class="uploadFile importFile mb-5">
                          <div class="upload-demo">
                            <input
                              ref="img-upload-input"
                              class="img-upload-input"
                              type="file"
                              accept=".png, .jpg, .jpeg"
                              @change="handleClick"
                            />
                            <div class="drop" @click="handleUpload">
                              <i class="el-icon-upload img-upload-icon"></i>
                              <br />
                              Drag and drop file <br />
                              or <br />
                              <div class="" style="font-size: larger">
                                <i class="fas fa-cloud-upload-alt"></i>
                                Upload
                              </div>

                              <div class="preview" v-if="createImage">
                                <img :src="url" />
                              </div>
                              <div class="class preview" v-else>
                                <img :src="module.image" />
                              </div>
                            </div>
                            <v-errors
                              :serverErrors="errorFor('image')"
                              :vuelidateErrors="{
                                errors: v$.module.image.$errors,
                                value: 'Image',
                              }"
                            ></v-errors>
                          </div>
                          <div
                            v-if="module.image"
                            @click="handleRemove"
                            id="remove"
                            class="icon-item"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-x icon-dual"
                            >
                              <line x1="18" y1="6" x2="6" y2="18"></line>
                              <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                  <div class="mb-3">
                    <label for="category" class="form-label">
                      Module Category
                      <span class="text-danger">*</span>
                    </label>
                    <v-select
                      v-model="module.category"
                      label="name"
                      :options="filterCategories"
                      :reduce="(name) => name.id"
                      :clearable="false"
                      :class="{
                        'p-invalid':
                          v$.module.category.$error || errorFor('category'),
                      }"
                    >
                    </v-select>
                    <v-errors
                      :serverErrors="errorFor('category')"
                      :vuelidateErrors="{
                        errors: v$.module.category.$errors,
                        value: 'Category',
                      }"
                    ></v-errors>
                  </div>
                  <div class="mb-3" v-if="module.category == '3'">
                    <label for="job_field" class="form-label">
                      Job Field
                    </label>
                    <v-select
                      v-model="module.job_field_id"
                      label="name"
                      :options="job_fields"
                      :reduce="(name) => name.id"
                      :clearable="false"
                    ></v-select>
                  </div>
                  <div class="mb-3">
                    <label for="trainning_method" class="form-label">
                      Training Method<span class="text-danger">*</span>
                    </label>
                    <v-select
                      v-model="module.trainning_method"
                      label="name"
                      :options="trainning_methods"
                      :clearable="false"
                      :class="{
                        'p-invalid':
                          v$.module.trainning_method.$error ||
                          errorFor('trainning_method'),
                      }"
                    >
                    </v-select>
                    <v-errors
                      :serverErrors="errorFor('trainning_method')"
                      :vuelidateErrors="{
                        errors: v$.module.trainning_method.$errors,
                        value: 'Training Method',
                      }"
                    ></v-errors>
                  </div>
                  <div class="mb-3">
                    <label for="competency" class="form-label">
                      Competency <span class="text-danger">*</span>
                    </label>
                    <v-select
                      multiple
                      v-model="module.competency"
                      label="name"
                      :options="competenciesId"
                      :reduce="(name) => name.id"
                      :class="{
                        'p-invalid':
                          v$.module.competency.$error || errorFor('competency'),
                      }"
                    >
                    </v-select>
                    <v-errors
                      :serverErrors="errorFor('competency')"
                      :vuelidateErrors="{
                        errors: v$.module.competency.$errors,
                        value: 'Competency',
                      }"
                    ></v-errors>
                  </div>
                  <div class="mb-3">
                    <label for="price" class="form-label">
                      Price <span class="text-danger">*</span>
                    </label>
                    <VueNumberFormat
                      v-model:value="module.price"
                      :options="{
                        precision: '',
                        prefix: '',
                        suffix: ' MMK',
                        thousand: ',',
                        acceptNegative: false,
                      }"
                      id="price"
                      name="price"
                      class="form-control"
                    ></VueNumberFormat>
                    <!-- <input
                      type="text"
                      id="price"
                      name="price"
                      class="form-control"
                      placeholder="Type Price"
                      v-model="module.price"
                      :class="{
                        'p-invalid':
                          v$.module.price.$error || errorFor('price'),
                      }"
                    /> -->
                    <v-errors
                      :serverErrors="errorFor('price')"
                      :vuelidateErrors="{
                        errors: v$.module.price.$errors,
                        value: 'Price',
                      }"
                    ></v-errors>
                  </div>
                  <div class="mb-3">
                    <label for="trainer" class="form-label">
                      Trainer<span class="text-danger">*</span>
                    </label>
                    <template v-if="currentRouteName == 'module-create'">
                      <v-select
                        multiple
                        class="custom-selectCSS"
                        v-model="module.trainer"
                        label="name"
                        :options="trainers"
                        :class="{
                          'p-invalid':
                            v$.module.trainer.$error || errorFor('trainer'),
                        }"
                      ></v-select>
                      <v-errors
                        :serverErrors="errorFor('trainer')"
                        :vuelidateErrors="{
                          errors: v$.module.trainer.$errors,
                          value: 'Trainer',
                        }"
                      ></v-errors>
                    </template>
                    <template v-else>
                      <v-select
                        disabled
                        multiple
                        class="custom-selectCSS"
                        v-model="module.trainer"
                        label="name"
                        :options="trainers"
                      ></v-select>
                    </template>
                  </div>
                </div>
                <!-- end col -->
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="participant" class="form-label">
                      Number Of Participant
                      <span class="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      id="participant"
                      name="participant"
                      class="form-control"
                      min="1"
                      oninput="this.value = !!this.value && Math.abs(this.value) >= 1 ? Math.abs(this.value) : null"
                      v-model="module.participant"
                      placeholder="Type Number Of Participant"
                      :class="{
                        'p-invalid':
                          v$.module.participant.$error ||
                          errorFor('participant'),
                      }"
                    />
                  </div>
                  <v-errors
                    :serverErrors="errorFor('participant')"
                    :vuelidateErrors="{
                      errors: v$.module.participant.$errors,
                      value: 'Participant',
                    }"
                  ></v-errors>
                  <div class="row">
                    <div class="col-lg-12">
                      <label for="hour" class="form-label">
                        Training Hour
                        <span class="text-danger">*</span>
                      </label>
                    </div>
                    <div class="mb-3">
                      <span class="custom-span"
                        ><input
                          type="text"
                          class="custom-input"
                          pattern="[0-9]"
                          v-model="hour"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                      /></span>
                      :
                      <span class="custom-span"
                        ><input
                          type="text"
                          class="custom-input"
                          pattern="[0-9]{10}"
                          maxlength="2"
                          min="0"
                          max="60"
                          v-model="minute"
                      /></span>
                      <span
                        :class="{
                          'p-invalid': v$.minute.$error,
                        }"
                      ></span>
                      <br />
                      <span
                        v-if="isMinute"
                        class="customValidate p-invalid text-danger"
                        >Minute must be 1 to 60!</span
                      >
                      <v-errors
                        :serverErrors="errorFor('hour')"
                        :vuelidateErrors="{
                          errors: v$.hour.$errors,
                          value: 'Hour',
                        }"
                      ></v-errors>
                      <v-errors
                        :serverErrors="errorFor('minute')"
                        :vuelidateErrors="{
                          errors: v$.minute.$errors,
                          value: 'Minute',
                        }"
                      ></v-errors>

                      <!-- <input
                      type="text"
                      name="duration"
                      class="form-control"
                      placeholder="Type Training Hour"
                      v-model="module.duration"
                      :class="{
                        'p-invalid':
                          v$.module.duration.$error || errorFor('duration'),
                      }"
                    /> -->
                    </div>
                  </div>

                  <!-- <div class="mb-3">
                    <label for="duration" class="form-label">
                      Training Hour <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="duration"
                      class="form-control"
                      placeholder="Type Training Hour"
                      v-model="module.duration"
                      :class="{
                        'p-invalid':
                          v$.module.duration.$error || errorFor('duration'),
                      }"
                    />
                  </div>
                  <v-errors
                    :serverErrors="errorFor('duration')"
                    :vuelidateErrors="{
                      errors: v$.module.duration.$errors,
                      value: 'Duration',
                    }"
                  ></v-errors> -->
                  <div class="mb-3">
                    <label for="objective" class="form-label">
                      Learning Objective
                      <span class="text-danger">*</span>
                    </label>
                    <textarea
                      name="objective"
                      id="learning_objective"
                      rows="8"
                      placeholder="Type Learning Objective"
                      v-model="module.learning_objective"
                      class="form-control"
                      :class="{
                        'p-invalid':
                          v$.module.learning_objective.$error ||
                          errorFor('learning_objective'),
                      }"
                    >
                    </textarea>
                    <v-errors
                      :serverErrors="errorFor('learning_objective')"
                      :vuelidateErrors="{
                        errors: v$.module.learning_objective.$errors,
                        value: 'Learning Objective',
                      }"
                    ></v-errors>
                  </div>

                  <div class="mb-3">
                    <label for="description" class="form-label">
                      Course Description
                      <span class="text-danger">*</span>
                    </label>
                    <textarea
                      name="description"
                      id="description"
                      rows="8"
                      placeholder="Type Course Description"
                      v-model="module.description"
                      class="form-control"
                      :class="{
                        'p-invalid':
                          v$.module.description.$error ||
                          errorFor('description'),
                      }"
                    ></textarea>
                    <v-errors
                      :serverErrors="errorFor('description')"
                      :vuelidateErrors="{
                        errors: v$.module.description.$errors,
                        value: 'Description',
                      }"
                    ></v-errors>
                  </div>
                  <div class="mb-3">
                    <label for="learning_outcome" class="form-label">
                      Learning Outcome
                      <span class="text-danger">*</span>
                    </label>
                    <textarea
                      name="learning_outcome"
                      id="learning_outcome"
                      rows="8"
                      placeholder="Type Learning Outcome"
                      v-model="module.learning_outcome"
                      class="form-control"
                      :class="{
                        'p-invalid':
                          v$.module.learning_outcome.$error ||
                          errorFor('learning_outcome'),
                      }"
                    ></textarea>
                    <v-errors
                      :serverErrors="errorFor('learning_outcome')"
                      :vuelidateErrors="{
                        errors: v$.module.learning_outcome.$errors,
                        value: 'Learning Outcome',
                      }"
                    ></v-errors>
                  </div>
                  <div class="mb-3" v-if="currentRouteName === 'module-update'">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      name="status"
                      v-model="module_deactivate"
                      :disabled="deactivate_status == 2 ? true : false"
                    />
                    Deactivate
                  </div>
                  <div class="mb-3" v-if="module_deactivate == true">
                    <textarea
                      name="reason"
                      id="reason"
                      rows="2"
                      placeholder="Type Reason"
                      v-model="reason"
                      :disabled="deactivate_status == 2 ? true : false"
                      class="form-control"
                      :class="{
                        'p-invalid': v$.reason.$error || errorFor('reason'),
                      }"
                    ></textarea>
                    <br /><v-errors
                      :serverErrors="errorFor('reason')"
                      :vuelidateErrors="{
                        errors: v$.reason.$errors,
                        value: 'Reason',
                      }"
                    ></v-errors>
                  </div>
                  <!-- end col -->
                </div>
              </div>
              <!-- end row -->
              <div class="row">
                <div class="col-md-12">
                  <div class="text-center mt-3 mb-3">
                    <router-link :to="{ name: 'modules' }">
                      <button type="button" class="btn w-sm btn-secondary me-5">
                        Cancel
                      </button>
                    </router-link>
                    <button
                      :disabled="isLoading ? true : false"
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3"
                      @click="
                        currentRouteName === 'module-update'
                          ? updateModule()
                          : createModule()
                      "
                    >
                      <span
                        v-if="isLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {{ isLoading == true ? "Loading..." : "Save" }}
                    </button>
                  </div>
                </div>
                <!-- end col -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import validationErrors from "../../mixins/validationErrors";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  mixins: [validationErrors],
  data() {
    return {
      trainning_methods: ["Online", "Inclass", "Outbound"],
      competenciesId: [],
      trainers: [],
      trainerLists: [],
      job_fields: [],
      categories: [],
      filterCategories: [],
      module: {
        name: "",
        short_name: "",
        category: "",
        job_field_id: "",
        trainning_method: "",
        competency: [],
        // price: 0,
        price: "",
        trainer: [],
        image: "",
        participant: "",
        // duration: "",
        hour: "",
        learning_objective: "",
        description: "",
        learning_outcome: "",
        model_name: "module",
      },
      // trashed_at: "",
      deactivate_status: "",
      module_deactivate: false,
      reason: "",
      minute: "",
      hour: "",
      baseUrl: process.env.VUE_APP_BASE_URL,
      // baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
      createImage: false,
      loading: false,
      isLoading: false,
      isMinute: false,
    };
  },
  validations() {
    return {
      module: {
        name: { required },
        short_name: { required },
        category: { required },
        trainning_method: { required },
        competency: { required },
        price: { required },
        trainer: { required },
        image: { required },
        participant: { required },
        // duration: { required },
        learning_objective: { required },
        description: { required },
        learning_outcome: { required },
      },
      hour: { required },
      minute: { required },
      reason: {
        required: requiredIf(() => {
          return this.module_deactivate != false;
        }),
      },
    };
  },
  watch: {
    minute(value) {
      this.validateInput(value);
    },
  },
  methods: {
    validateInput(value) {
      if (value != "") {
        if (isNaN(this.hour)) {
          this.hour = "";
        }
        if (value > 60) {
          this.minute = "";
          this.isMinute = true;
          // this.toast.error("Minute must be within 1 and 60 minutes!");
        } else if (isNaN(value)) {
          this.minute = "";
        } else {
          this.isMinute = false;
        }
      }
    },

    getCompetencies() {
      axios
        .get(`${this.baseUrl}admin/v1/competency`)
        .then((response) => {
          this.competenciesId = response.data.data;
        })
        .catch(() => {
          this.toast.error("Not Found Competencies!");
        });
    },
    getTrainers() {
      axios
        .get(`${this.baseUrl}admin/v1/trainers`)
        .then((response) => {
          this.trainers = response.data.data.map(function (val) {
            //for active trainers
            if (val.status == 1) {
              return {
                employee_id: val.employee_id,
                name: val.name,
              };
            }
          });
        })
        .catch((err) => {
          console.log(err);
          this.toast.error("Not Found Trainer!");
        });
    },
    getCategories() {
      axios
        .get(`${this.baseUrl}admin/v1/categories`)
        .then((response) => {
          this.categories = response.data.data;
          this.filterCategories = this.categories.filter(
            (item) => !item.name.includes("All")
          );
        })
        .catch(() => {
          this.toast.error("Not Found Categories");
        });
    },

    getJobFields() {
      axios
        .get(`${this.baseUrl}admin/v1/jobfield`)
        .then((response) => {
          this.job_fields = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.toast.error("Not Found Job Fields!");
        });
    },

    handleUpload() {
      this.$refs["img-upload-input"].click();
    },

    handleClick(e) {
      this.module.image = null;
      this.url = null;
      const file = e.target.files[0];
      this.module.image = file;
      this.url = URL.createObjectURL(file);
      this.createImage = true;
    },
    handleRemove() {
      this.module.image = null;
      this.url = null;
    },

    async createModule() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.toast.error("Please fill all input!");
        return;
      }

      let form = new FormData();

      var formatedCompetency = JSON.stringify(this.module.competency);
      form.append("competencies", formatedCompetency);
      this.module.trainer.forEach((trainer) => {
        form.append("trainer_id[]", trainer.employee_id);
      });

      form.append("name", this.module.name);
      form.append("short_name", this.module.short_name);
      form.append("category", this.module.category);
      form.append("job_field_id", this.module.job_field_id);
      form.append("trainning_method", this.module.trainning_method);
      form.append("price", this.module.price);
      form.append("participant", this.module.participant);
      form.append("duration", this.hour + ":" + this.minute);

      form.append("module_profile", this.module.image);
      form.append("model_name", this.module.model_name);
      form.append("description", this.module.description);
      form.append("learning_objective", this.module.learning_objective);
      form.append("learning_outcome", this.module.learning_outcome);

      this.isLoading = true;
      axios
        .post(`${this.baseUrl}admin/v1/modules`, form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.$router.push({ name: "modules" });
          this.toast.success("Successfully Module Created!");
          this.isLoading = false;
        })
        .catch((error) => {
          // this.errors = error.response.data.errors;
          console.log(error);
          this.toast.error("Something Went Wrong!");
          this.isLoading = false;
        });
    },
    async getModuleById() {
      this.loading = true;
      await axios
        .get(`${this.baseUrl}admin/v1/modules/${this.$route.params.id}`)
        .then((response) => {
          const result = response.data.data;

          this.module = result;
          var duration = this.module.duration.split(":");
          this.module.category = result.category_id;
          this.module.competency = JSON.parse(result.competencies);
          this.module.job_field_id = result.job_field_id;
          this.hour = duration[0];

          this.minute = duration[1];

          result.trainers.forEach((trainer) => {
            this.trainerLists.push(trainer.pgsql_employee);
          });

          this.module.trainer = this.trainerLists;
          this.module.image = result.media.image_url;
          // this.trashed_at = result.trashed_at;
          // if (result.trashed_at != null) {
          //   this.module_deactivate = true;
          //   this.reason = result.deactivate_request_remark;
          // }
          this.deactivate_status = result.deactivate_status;
          if (result.deactivate_status == 2) {
            this.module_deactivate = true;
            this.reason = result.module_deactivations[0].remark;
          }
        })
        .catch(() => {
          this.toast.error("Not Found Module!");
        });

      this.loading = false;
    },
    async updateModule() {
      // eslint-disable-next-line no-unreachable
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.toast.error("Please fill all input!");
        return;
      }
      let updateForm = new FormData();

      var formatedCompetency = JSON.stringify(this.module.competency);
      updateForm.append("competencies", formatedCompetency);

      this.module.trainer.forEach((trainer) => {
        updateForm.append("trainer_id[]", trainer.employee_id);
      });

      // eslint-disable-next-line no-unreachable
      if (this.module.category == "3") {
        updateForm.append("job_field_id", this.module.job_field_id);
      }

      updateForm.append("name", this.module.name);
      updateForm.append("short_name", this.module.short_name);

      updateForm.append("category", this.module.category);
      updateForm.append("trainning_method", this.module.trainning_method);

      updateForm.append("price", this.module.price);
      updateForm.append("module_profile", this.module.image);
      updateForm.append("participant", this.module.participant);
      updateForm.append("duration", this.hour + ":" + this.minute);
      updateForm.append("learning_objective", this.module.learning_objective);
      updateForm.append("description", this.module.description);
      updateForm.append("learning_outcome", this.module.learning_outcome);
      updateForm.append("module_deactivate", this.module_deactivate);
      updateForm.append("reason", this.reason);
      if (this.module_deactivate == true) {
        updateForm.append("employee_name", this.$store.state.auth.user.name);
        updateForm.append(
          "employee_id",
          this.$store.state.auth.user.employee_id
        );
        updateForm.append("employee_email", this.$store.state.auth.user.email);
      }
      updateForm.append("_method", "put");
      this.isLoading = true;
      axios
        .post(
          `${this.baseUrl}admin/v1/modules/${this.$route.params.id}`,
          updateForm,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(() => {
          this.$router.push({ name: "modules" });
          this.toast.success("Successfully Module Updated!");
          this.isLoading = false;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
          this.isLoading = false;
        });
    },
  },

  created() {
    this.getCategories();
    if (this.currentRouteName == "module-update") {
      this.getModuleById();
    }
    this.getCompetencies();
    this.getTrainers();
    this.getJobFields();
  },

  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  components: {
    vSelect,
  },
};
</script>

<style>
.custom-selectCSS {
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 200px;
}
</style>

<style scoped>
.img-upload-input {
  display: none;
  z-index: -9999;
}

.img-upload-icon {
  font-size: 67px;
  color: #c0c4cc;
  margin: 40px 0 16px;
  line-height: 50px;
}

.drop,
.preview {
  min-height: 220px;
  border: 2px dashed #d9d9d9;
  width: 100%;
  height: auto;
  margin: auto;
  font-size: 14px;
  border-radius: 5px;
  text-align: center;
  color: #bbb;
  position: relative;
  /* background-image: linear-gradient(#71d5e3, #dcebe5) !important; */
}

.preview {
  position: absolute;
  top: 0;
  border: unset;
}

.preview img {
  max-width: 100%;
  max-height: 215px;
}

.uploadFile {
  position: relative;
  width: 100%;
  height: 100%;
}

#remove {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer !important;
  z-index: 5;
  text-align: center !important;
  font-size: 1em;
  width: 1.8em;
  height: 1.8em;
  color: #fff;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: none;
  box-shadow: 0 0 0 0 hsl(0deg 0% 100% / 0%);
  transition: box-shadow 0.25s ease-in;
}

#remove > .icon-dual {
  color: #fff !important;
  font-size: 1em;
}

.custom-input {
  /* background-color: rgb(25, 11, 222); */
  border: 1px solid #ced4da;
  border-radius: 5px;
  display: inline-block;
  /* color: #fff; */
  margin: 0.2em 0;
  padding: 0.5em;
  width: 10%;
}

.custom-input:focus-visible {
  outline-color: #b6bcc1 !important;
  outline-width: 0.1px;
  /* outline: none !important; */
}

.customValidate {
  font-size: 0.75rem;
}
</style>
